import Router from './js/util/Router';
import common from './js/routes/common';
import home from './js/routes/home';
import singleProject from './js/routes/singleProject';
import pageTemplatePageClients from './js/routes/pageTemplatePageClients';
import pageTemplatePageWhatWeDo from './js/routes/pageTemplatePageWhatWeDo';
import pageTemplatePageWhoWeAre from './js/routes/pageTemplatePageWhoWeAre';
import pageTemplatePageHowWeWork from './js/routes/pageTemplatePageHowWeWork';
import pageTemplatePageContact from './js/routes/pageTemplatePageContact';
import pageTemplatePageCareers from './js/routes/pageTemplatePageCareers';
import pageTemplatePageNewsroom from './js/routes/pageTemplatePageNewsroom';

import './scss/application.scss';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	/** About Us page, note the change from about-us to aboutUs. */
	singleProject,
	pageTemplatePageWhatWeDo,
	pageTemplatePageWhoWeAre,
	pageTemplatePageHowWeWork,
	// Contact Us page, note the change from contact to contactUs.
	pageTemplatePageContact,
	// Careers page
	pageTemplatePageCareers,
	// Clients page
	pageTemplatePageClients,
	// Newsroom page
	pageTemplatePageNewsroom,
});

/** Load Events */
jQuery(document).ready(() => {
	routes.loadEvents()
});
