require('fullpage.js/dist/fullpage.css');
require('../util/fullpage.scrollHorizontally.min.js');
// require('../util/fullpage.parallax.min.js')
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

export default {
	init() {
		console.log('who we are');

		//const footer = document.querySelector('footer');
		//footer.classList.add('!hidden');

		jQuery(document).ready(function ($) {
			var $logos = $('.units_logos'),
				$items = $('.js-toggle-unit'),
				$footer = $('#fullpage_footer'),
				$fixedFooter = $footer.clone(),
				indexUnit = 0,
				logosShown = false;

			//manageFooter();

			$items.bind('click', function (e) {
				e.preventDefault();
				if (!$(this).hasClass('active')) {
					$items.removeClass('active flex-1');
					$(this).addClass('active flex-1');
					indexUnit = $(this).index();
				}
			});

			new fullpage('#fullpage', {
				licenseKey: 'MWCWH-TS6JK-A6NJ9-82WK9-KEJAL',
				credits: { enabled: false },
				scrollOverflow: false,
				verticalCentered: false,
				responsiveWidth: 768,
				normalScrollElements: '#masthead',
				lazyLoading: true,
				beforeLeave: function (
					origin,
					destination,
					direction,
					trigger
				) {
					if (
						origin.index == 0 &&
						!logosShown &&
						direction == 'down' &&
						trigger == 'wheel'
					) {
						$logos.removeClass('md:translate-y-full');
						$logos.addClass('mb-4');
						logosShown = true;
						return false;
					}

					// if (origin.index == 1) {
					// 	direction == 'down'
					// 		? $fixedFooter.addClass('visible')
					// 		: $fixedFooter.removeClass('visible');
					// }

					/* if (origin.index == 1 && trigger == 'wheel') {
						if (direction == 'down') {
							if (indexUnit < $items.length - 1) {
								$items.removeClass('active flex-1');
								$($items[indexUnit + 1]).addClass(
									'active flex-1'
								);
								indexUnit++;
								return false;
							}
						} else {
							if (indexUnit > 0) {
								$items.removeClass('active flex-1');
								$($items[indexUnit - 1]).addClass(
									'active flex-1'
								);
								indexUnit--;
								return false;
							}
						}
					} */
				},
				afterLoad: function (origin, destination, direction, trigger) {
					if (destination.index == 0 && logosShown) {
						$logos.addClass('md:translate-y-full');
						$logos.removeClass('mb-4');
						logosShown = false;
						return false;
					}
				},
				afterRender: function () {
					//footer.classList.remove('!hidden');
				},
			});

			function manageFooter() {
				$footer.addClass('real-footer');
				$fixedFooter.addClass('clone-footer');
				$('#fullpage').after($fixedFooter);
			}
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
