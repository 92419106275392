import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
	init() {
		console.log('clients');	
		const clientHero = document.querySelector('#at_client_hero');
		const clientHeroTitle = document.querySelector('#at_client_hero h1');
		const clientHeroSpan = document.querySelector('#at_client_hero p');
		const clientLoghi = document.querySelector('#at_client_loghi');

		// gsap.registerPlugin(ScrollTrigger);

		// let mm = gsap.matchMedia();

		// let clientTl = gsap.timeline({
		// 	scrollTrigger: {
		// 		trigger: clientHero,
		// 		start: 'top top',
		// 		end: 'bottom top',
		// 		pin: true,
		// 		scrub: 1,
		// 		// markers: true,
		// 	},
		// });

		// mm.add("(min-width: 768px)", () => {
		// 	clientTl.to(
		// 		clientLoghi, { 
		// 			y: '0', 
		// 			duration: 20000, 
		// 			ease: 'linear',
		// 		}
		// 	);
		// });

		// //media query
		// mm.add("(max-width: 767px)", () => {
			
		// 	clientTl.to(
		// 		clientHeroTitle, { 
		// 			opacity: 0, 
		// 			duration: 0.5, 
		// 			ease: 'linear',
		// 		}
		// 	);
		// 	clientTl.to(
		// 		clientHeroSpan, { 
		// 			opacity: 0, 
		// 			duration: 0.5, 
		// 			ease: 'linear',
		// 		}
		// 	);

		// 	clientTl.to(
		// 		clientLoghi, { 
		// 			y: '0', 
		// 			duration: 20000, 
		// 			ease: 'linear',
		// 		}
		// 	);

		// });


	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
