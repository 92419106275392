
export default {
	init() {
		console.log('newsroom');

		jQuery(document).ready(function($) {
			let loadMoreBtn = $('#loadMoreBtn'),
				newsroomContainer = $('#at-newsroom-container'),
				noPostsMsg = $('#noPostsMsg'),
				paged = 4;


			loadMoreBtn.bind('click', function (e) {
				e.preventDefault();
				paged = paged + 4;
				loadPosts();
			});
			
			function loadPosts() {
				newsroomContainer.addClass('loading');
				var searchQuery = $('#search').val(); // Ottieni il valore di ricerca
				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					data: {
						action: 'newsroom_showMore',
						paged: paged,
						search: searchQuery
					},
					success: function(response) {
						var res = JSON.parse(response);
						console.log(res.html)
						if(res.html){
							if(res.numPosts > paged){
								newsroomContainer.html(res.html); // Aggiorna la sezione dei risultati
							}
							else{
								newsroomContainer.html(res.html);
								loadMoreBtn.hide();
							}
							newsroomContainer.removeClass('loading');
						}
						else {
							noPostsMsg.show();
							loadMoreBtn.hide();
						}
					},
					fail: function (err) {
						console.log('There was an error: ' + err);
					}
				});
			}
			
			$('#search-form').on('submit', function(e) {
				e.preventDefault(); // Evita il comportamento predefinito del form
				newsroomContainer.addClass('loading'); // Aggiungi la classe loading alla sezione dei risultati
				$('#clearSearchBtn').removeClass('hidden'); // Mostra il bottone clear search
				var searchQuery = $('#search').val(); // Ottieni il valore di ricerca
			
				$.ajax({
					url: '/wp-admin/admin-ajax.php',
					type: 'POST',
					data: {
						action: 'search_newsroom',
						search: searchQuery
					},
					success: function(response) {
						var res = JSON.parse(response);
						if(res.html) {
							newsroomContainer.html(res.html); // Aggiorna la sezione dei risultati
						} else {
							newsroomContainer.html('<p>No results found.</p>'); // Mostra un messaggio di nessun risultato
						}
						newsroomContainer.removeClass('loading');
					},
					fail: function(err) {
						console.log('There was an error: ' + err);
					}
				});
			});

			// Gestione del click sul bottone clear search
			$('#clearSearchBtn').bind('click', function(e) {
				e.preventDefault(); // Evita il comportamento predefinito del bottone
				$('#search').val(null); // Ripulisci il campo di ricerca
				loadPostsInitialPost(); // Ricarica i post iniziali
			});

			function loadPostsInitialPost() {
				newsroomContainer.addClass('loading');
				paged = 4;
				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					data: {
						action: 'newsroom_showMore',
						paged: paged
					},
					success: function(response) {
						var res = JSON.parse(response);
						
						if(res.html){
							newsroomContainer.html(res.html); // Aggiorna la sezione dei risultati
							newsroomContainer.removeClass('loading');
							$('#clearSearchBtn').addClass('hidden');
						}
						else {
							noPostsMsg.show();
							loadMoreBtn.hide();
						}
						

						// if(res.html){
						// 	newsroomContainer.html(res.html); // Aggiorna la sezione dei risultati
						// 	newsroomContainer.removeClass('loading');
						// }
						// else {
						// 	noPostsMsg.show();
						// 	loadMoreBtn.hide();
						// }
					},
					fail: function (err) {
						console.log('There was an error: ' + err);
					}
				});
			}

		});

		
		
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
