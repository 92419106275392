import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
	init() {
		console.log('single project');

		// Animazione titolo + video Single Project
		const singleProjectContainer = document.querySelector(
			'#at-project-video-container'
		);

		if (singleProjectContainer) {
			const singleProjectTitle = document.querySelector(
				'#at-project-video-text'
			);
			const singleProjectImage = document.querySelector(
				'#at-project-video-img'
			);
			const singleProjectBtn = document.querySelector(
				'#at-project-btn-play'
			);

			const singleProjectTl = gsap.timeline({
				scrollTrigger: {
					trigger: singleProjectContainer,
					start: 'top top',
					end: 'bottom center', // se non piace rimettere "bottom center"
					scrub: 1,
					pin: true,
					//markers: true,
				},
			});

			// Animazione per il titolo
			singleProjectTl.to(singleProjectTitle, {
				y: '-200%',
				duration: 1,
				ease: 'none',
			});

			// Aggiungi animazione per l'immagine
			singleProjectTl.to(
				singleProjectImage,
				{
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					duration: 1,
					ease: 'none',
				},
				'<'
			);

			// Aggiungi animazione per il bottone
			singleProjectTl.to(singleProjectBtn, {
				opacity: 1,
				visibility: 'visible',
				duration: 1,
				delay: 0.2,
				ease: 'none',
			})

			// Aggiungi un po' di spazio in basso
			singleProjectTl.to(singleProjectContainer, {
				height: '20vh',
				duration: 1,
				ease: 'none',
			});
		}

		//Wall block
		const wallBlock = document.querySelector('.wall_block');
		if (wallBlock) {
			let wallBlockTl = gsap.timeline({
				scrollTrigger: {
					trigger: wallBlock,
					start: 'top bottom',
					end: 'top 20%',
					scrub: 1,
				},
			});
			wallBlockTl.to('.wall_block img', {
				y: '0%',
				duration: 1,
				ease: 'none',
			});
		}

		let lastSlide;

		// Inizializza Swiper
		new Swiper('.at_project_slider', {
			modules: [Pagination],
			slideToClickedSlide: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'progressbar',
			},
			slidesPerView: 1,
			loop: true,
			on: {
				reachEnd: function () {
					setTimeout(() => {
						lastSlide = true;
					}, 500);
				},
				click: function () {					
					if(this.activeIndex == this.slides.length - 1 && lastSlide){
						lastSlide = false;
						this.slideToLoop(0, 2000)
					}
					
				},
				


			},
		});

		new Swiper('.related_swiper', {
			slidesPerView: 'auto',
		});

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
