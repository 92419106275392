import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
	init() {
		console.log('contact us');
		// JavaScript to be fired on the contact us page
		
		gsap.registerPlugin(ScrollTrigger);

		ScrollTrigger.create({
			trigger: '#at-contact-hero',
			start: 'top top',
			end: 'bottom bottom',
			pin: '.hero-pinned',
			scrub: true,
		});

		new Swiper('.at_contact_address_slider', {
			modules: [Pagination],
			slidesPerView: 4,
			breakpoints: {
				320: {
					slidesPerView: 1.3,
				},
				480: {
					slidesPerView: 2.1,
				},
				768: {
					slidesPerView: 3.1,
				},
				1024: {
					slidesPerView: 4,
				},
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'progressbar',
			},
		});
		
	},
	finalize() {
		
	},
};
