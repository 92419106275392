require('fullpage.js/dist/fullpage.css')
require('../util/fullpage.parallax.min.js')
import fullpage from 'fullpage.js/dist/fullpage.extensions.min'
import { gsap } from 'gsap'

export default {
  init() {
    console.log('home');
    const body = document.querySelector('body');
    const header = document.querySelector('#masthead');
    const headerMenu = document.querySelector('#at-menu');
    const menuLogo = document.querySelector('#at-menu-logo');
    const languageSwitcher = document.querySelector('#at-language-switcher');
    const hamburger = document.querySelector('#at-hamburger');
    const childrenHamburger = Array.from(hamburger.children);
    const mosaicContainer = document.querySelectorAll('.at-iconic-mosaic-container')
    const titleHp = document.querySelector('#at-hp-tile-hero');


    // Animazione titolo entrata HP
		if (titleHp) {
			gsap.from(titleHp, {
				y: '100%',
				opacity: 0,
				duration: 1,
				ease: 'power4.out',
				delay: 1,
			});
		}

    //MOSAICO SINCE ICONIC
    const randomArray = []
    function changeMosaic() {
      // Se l'array è pieno, svuotalo
      if(randomArray.length === mosaicContainer.length) {
        randomArray.length = 0;
      }
      
      // Genera un numero casuale e assicurati che non sia già presente nell'array
      let random;
      do {
        random = Math.floor(Math.random() * mosaicContainer.length);
      } while (randomArray.includes(random));
    
      randomArray.push(random);

      const mosaic = mosaicContainer[random]

      const mosaicItems = mosaic.querySelectorAll('img')

      // Nascondi tutti gli elementi
      mosaicItems.forEach(item => {
        if(!item.classList.contains('hidden')) {
          item.classList.add('hidden')
        }
      })

      const randomItem = Math.floor(Math.random() * mosaicItems.length)

      // Mostra un elemento casuale
      if(mosaicItems[randomItem].classList.contains('hidden')) {
        mosaicItems[randomItem].classList.remove('hidden')
      }
  
    }
    setInterval(changeMosaic, 1000);

    //MENU
    function menuScrollColorHp(){
			//quando arrivo all above the fold cambio i colori del menu, solo in home

			const aboveTheFold = document.querySelector('.at_atf');

			if(body.classList.contains('home')){
				
				let aboveTheFoldPosition = aboveTheFold.getBoundingClientRect().top;

				if(aboveTheFoldPosition <= 0 && aboveTheFoldPosition < -header.offsetHeight) {
					
          header.classList.add('bg-white');
					menuLogo.querySelector('svg').style.fill = 'black';
          languageSwitcher.classList.remove('text-white');
          colorSwitcher('black');
          childrenHamburger.forEach((child) => {
            child.classList.add('bg-black');
            child.classList.remove('bg-white');
          });
				}
				else {
          header.classList.remove('bg-white');
					menuLogo.querySelector('svg').style.fill = 'white';
          languageSwitcher.classList.add('text-white');
          colorSwitcher('white');
          childrenHamburger.forEach((child) => {
            child.classList.add('bg-white');
            child.classList.remove('bg-black');
          });
				}


			}	

		}
		document.addEventListener('scroll', menuScrollColorHp);

    function colorSwitcher(color){
      //ciclo su tutti i figli del languageSwitcher
      var childrenSwitcher = Array.from(languageSwitcher.children);

      if(color == 'black'){
        childrenSwitcher.forEach((child) => {
          if(child.classList.contains('border-white') || child.classList.contains('border-black' ) ){
            child.classList.remove('border-white');
            child.classList.add('border-black');
          }
          if(child.classList.contains('bg-white') || child.classList.contains('bg-black') ){
            child.classList.remove('bg-white');
            child.classList.add('bg-black');
          }
        });
      }
      else {
        childrenSwitcher.forEach((child) => {
          if(child.classList.contains('border-white') || child.classList.contains('border-black' ) ){
            child.classList.add('border-white');
            child.classList.remove('border-black');
          }
          if(child.classList.contains('bg-white') || child.classList.contains('bg-black') ){
            child.classList.add('bg-white');
            child.classList.remove('bg-black');
          }
        });
      }
    }

    //LOGHI CLIENTI SCROLL INFINITO
    const scrollers = document.querySelectorAll(".scroller");
    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
